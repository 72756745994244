<template>
  <div>
    <b-modal
      ref="add-inventory-check-modal"
      title="Tạo phiếu kiểm kê"
      hide-footer
    >
      <b-card
        bg-variant="light"
        text-variant="dark"
        class="mb-5"
      >
        <b-card-text>
          <b-form>
            <b-form-group
              label="Cửa hàng:"
              label-for="store"
            >
              <Autosuggest
                :model="searchStore"
                :disabled="disabled"
                :suggestions="filteredOptionsStore"
                @change="onInputChange"
                @select="onSelectedStore"
                size="sm"
                suggestionName="name"
                placeholder="tên cửa hàng"
              />
              <b-form-invalid-feedback
                id="input-store-live-feedback"
                :state="validateState('searchStore')"
              >Vui lòng nhập
                cửa hàng</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              label="Chọn loại sản phẩm:"
              label-for="product-type"
            >
              <b-form-select
                id="product-type"
                size="sm"
                v-model="selectedProductType"
                :options="listProductType"
                value-field="id"
                text-field="name"
                class="select-style"
              ></b-form-select>
            </b-form-group>
            <b-form-group label="Chọn danh mục sản phẩm:">
              <treeselect
                :options="filteredCateOptions"
                :multiple="true"
                :searchable="true"
                :match-keys="['id', 'label']"
                placeholder="Chọn danh mục"
                :clearable="true"
                :show-count="true"
                openDirection="bottom"
                v-model="selectedCategories"
              />
            </b-form-group>
          </b-form>
        </b-card-text>
      </b-card>
      <hr />
      <div class="d-flex justify-content-end">
        <b-button
          class="mr-3"
          @click="hideModal()"
        >Hủy</b-button>
        <b-spinner v-if="onLoading" label="Loading..."></b-spinner>
        <b-button
          variant="primary"
          @click="createStockCheckSheet()"
          v-else
        >Tạo</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { required } from 'vuelidate/lib/validators';
import { cloneDeep } from '@/utils/common';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import {debounce} from 'lodash';
// import { TIME_TRIGGER } from '../../../utils/constants';

export default {
  data() {
    return {
      filteredOptionsStore: [],
      selectedCategories: [],
      filteredCateOptions: [],
      optionsStore: [],
      selectedClassStore: null,
      searchStore: '',
      disabled: false,
      selectedProductType: null,
      shortNameStore: '',
      listProductType: [
        { id: null, name: 'Tất cả' },
        { id: 1, name: 'Sản phẩm' },
        { id: 2, name: 'Sản phẩm theo IMEI' },
      ],
      items: [],
      onLoading: false,
      listStorePermission: [88034, 88038, 101777, 88036, 101832]
    };
  },
  created() {
    this.fetchStore();
    // this.fetchStoreByUser();
    this.fetchCategory();
  },
  validations: {
    searchStore: {
      required,
    },
  },
  methods: {
    showModal() {
      this.$refs['add-inventory-check-modal'].show();
    },
    hideModal() {
      this.$refs['add-inventory-check-modal'].hide();
    },
    fetchCategory: function () {
      const param = {
        page: 1,
        limit: 1000,
        name: '',
        code: '',
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('category/get-list-category', paramQuery).then(
        ({ data }) => {
          this.filteredCateOptions = data.data.list_caterogy;
        },
      );
    },
    debounceSubmitForm: debounce(function(){
      this.submitForm()
    },3000),
    createStockCheckSheet(){
      this.onLoading = true;
      if (!this.searchStore) {
        makeToastFaile('Vui lòng chọn cửa hàng!');
        this.onLoading= false;
        return;
      }
      this.debounceSubmitForm()
    },
    async submitForm() {
      this.onLoading = true;
      this.filterdOptions = cloneDeep(this.optionStores);
      const data = {
        storeId: this.selectedClassStore,
        storeName: this.searchStore,
        productType: this.selectedProductType,
        shortNameStore: this.shortNameStore,
        categories: this.selectedCategories,
      };
      ApiService.post('v2/stock-check-sheet', data)
        .then(({ data }) => {
          this.hideModal();
          makeToastSuccess(data.message);
          this.$emit('fetchInventoryChecks');
          this.onLoading= false;
        })
        .catch((error) => {
          this.onLoading= false;
          makeToastFaile(error.response.data.message);
        })
    },
    fetchStore: async function () {
      this.optionsStore = [];
      ApiService.setHeader();
      const params = {
        listId: this.listStorePermission
      }
      ApiService.query('/stores/getStores', { params }).then((response) => {
        const stores = response.data.data;
        this.optionsStore = stores;
        this.filteredOptionsStore = stores;
      });
    },
    fetchStoreByUser() {
      ApiService.setHeader();
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          const stores = data.data.stores;
          if (stores.length === 1) {
            this.selectedClassStore = stores[0].id;
            this.searchStore = stores[0].name;
            this.shortNameStore = stores[0].shortName;
            this.storeName = stores[0].storeName;
            this.disabled = true;
          } else {
            this.optionsStore = stores;
            this.filteredOptionsStore = stores;
          }
        }
      });
    },
    onSelectedStore(option) {
      this.searchStore = option.item.name;
      this.selectedClassStore = option.item.id;
      this.shortNameStore = option.item.shortName;
    },
    validateState(name) {
      if (this.isSubmit) {
        const { $dirty, $error } = this.$v[name];
        return $dirty ? !$error : null;
      }
    },
    onInputChange(text) {
      if (text === '') {
        this.filteredOptionsStore = this.optionsStore;
      }
      this.searchStore = text;
      const filteredData = this.optionsStore
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionsStore = filteredData;
    },
  },
  components: { Autosuggest, Treeselect },
};
</script>

<style>
.input-style {
  border: 1px solid #ced4da;
}

.stock-info .card-body {
  padding: 1rem;
}

.form-group label {
  font-weight: 500;
}
</style>
